import React from "react"
import theme from "theme"
import {
  Theme,
  Text,
  Box,
  Image,
  LinkBox,
  Section,
  Button,
} from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"about"} />
      <Helmet>
        <title>Про нас | Відкрийте для себе Legal Pro Firm</title>
        <meta
          name={"description"}
          content={"Де кожна справа - це подорож, яку варто пройти"}
        />
        <meta
          property={"og:title"}
          content={"Про нас | Відкрийте для себе Legal Pro Firm"}
        />
        <meta
          property={"og:description"}
          content={"Де кожна справа - це подорож, яку варто пройти"}
        />
        <meta
          property={"og:image"}
          content={"https://ua.rivoxenpro.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://ua.rivoxenpro.com/img/icon.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://ua.rivoxenpro.com/img/icon.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://ua.rivoxenpro.com/img/icon.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://ua.rivoxenpro.com/img/icon.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://ua.rivoxenpro.com/img/icon.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://ua.rivoxenpro.com/img/icon.png"}
        />
        <meta
          name={"msapplication-TileColor"}
          content={"https://ua.rivoxenpro.com/img/icon.png"}
        />
      </Helmet>
      <Components.Header />
      <Section
        padding="80px 0 80px 0"
        lg-padding="56px 0 56px 0"
        sm-padding="32px 0 32px 0"
        background="--color-light"
      >
        <Box
          margin="0px 0px 32px 0px"
          md-margin="0px 0px 40px 0px"
          lg-margin="0px 0px 33px 0px"
          display="flex"
          flex-direction="column"
          align-items="center"
          lg-padding="15px 15px 15px 15px"
          sm-margin="0px 0px 10px 0px"
          justify-content="center"
        >
          <Text
            font="--headline1"
            color="--dark"
            text-align="center"
            md-font='normal 700 42px/1.2 "Source Sans Pro", sans-serif'
            margin="16px 0px 0px 0px"
          >
            Наша історія
          </Text>
          <Text
            font="--lead"
            color="--darkL2"
            text-align="left"
            md-width="100%"
            max-width="1000px"
          >
            Ласкаво просимо до серця Legal Pro Firm. Наш шлях розпочався з
            простої місії: демістифікувати юридичний процес для всіх, хто
            намагається орієнтуватися в часто складному юридичному світі. Ми
            побудували наш фундамент на принципах чесності, прозорості та
            непохитної підтримки наших клієнтів. Наша мета - забезпечити вас
            знаннями та порадами, необхідними для прийняття обґрунтованих рішень
            на вашому юридичному шляху.
          </Text>
        </Box>
        <LinkBox
          display="flex"
          md-flex-direction="column"
          md-align-items="center"
          md-justify-content="center"
          flex-wrap="wrap"
          width="100%"
          margin="0px 0px 16px 0px"
          justify-content="flex-start"
          md-margin="0px 0px 16px 0px"
        >
          <Box
            width="100%"
            align-items="flex-start"
            display="flex"
            justify-content="flex-start"
            md-width="100%"
            lg-width="100%"
          >
            <Image
              src="https://ua.rivoxenpro.com/img/6.jpg"
              border-radius="24px"
              max-width="100%"
              max-height="522px"
              width="100%"
              object-fit="cover"
              lg-max-height="392px"
              object-position="0% 30%"
              sm-max-height="213px"
            />
          </Box>
          <Box
            width="100%"
            display="flex"
            align-items="flex-start"
            justify-content="flex-start"
            md-border-width="0px"
            flex-direction="column"
            padding="16px 12px 16px 12px"
          >
            <Text font="--headline3" color="--dark" margin="0px 0px 8px 0px">
              Наш досвід
            </Text>
            <Text margin="0px 0px 0px 0px" font="--lead" color="--grey">
              В Юридичній фірмі "Легал Про" працює команда професіоналів, кожен
              з яких має власний унікальний досвід та пристрасть до правосуддя.
              Широкий спектр спеціалізацій нашої команди означає, що ми здатні
              вирішувати найрізноманітніші юридичні проблеми, від простих до
              найскладніших. Таке розмаїття не лише збагачує нашу фірму, але й
              гарантує, що незалежно від ваших юридичних потреб, ми маємо
              досвід, який допоможе вам.
            </Text>
          </Box>
        </LinkBox>
        <LinkBox
          display="flex"
          md-flex-direction="column"
          md-align-items="center"
          md-justify-content="center"
          flex-wrap="wrap"
          width="100%"
          margin="0px 0px 16px 0px"
          justify-content="flex-start"
          md-margin="0px 0px 16px 0px"
        >
          <Box
            width="100%"
            align-items="flex-start"
            display="flex"
            justify-content="flex-start"
            md-width="100%"
            lg-width="100%"
          >
            <Image
              src="https://ua.rivoxenpro.com/img/7.jpg"
              border-radius="24px"
              max-width="100%"
              max-height="522px"
              width="100%"
              object-fit="cover"
              lg-max-height="392px"
              object-position="0% 30%"
              sm-max-height="213px"
            />
          </Box>
          <Box
            width="100%"
            display="flex"
            align-items="flex-start"
            justify-content="flex-start"
            md-border-width="0px"
            flex-direction="column"
            padding="16px 12px 16px 12px"
          >
            <Text font="--headline3" color="--dark" margin="0px 0px 8px 0px">
              Що нас відрізняє
            </Text>
            <Text margin="0px 0px 0px 0px" font="--lead" color="--grey">
              Клієнтоорієнтований підхід: Ми надаємо пріоритет вашим потребам і
              цілям, пропонуючи персоналізоване керівництво на кожному кроці.
              <br />
              <br />
              Інноваційні рішення: Використання новітніх правових технологій та
              стратегій для досягнення ефективних та результативних результатів.
              <br />
              <br />
              Освіта та розширення прав і можливостей: Ми віримо в навчання
              наших клієнтів, гарантуючи, що ви розумієте свої права та
              можливості.
              <br />
              <br />
              Прагнення до досконалості: Наше невпинне прагнення до
              справедливості та досконалості в усьому, що ми робимо, вирізняє
              нас з-поміж інших.
            </Text>
          </Box>
        </LinkBox>
      </Section>
      <Section
        padding="60px 0 60px 0"
        sm-padding="40px 0"
        min-height="300px"
        sm-min-height="auto"
        background="--color-light"
        display="flex"
        flex-direction="column"
      >
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Text
          font="--base"
          text-transform="uppercase"
          letter-spacing="1px"
          color="--grey"
          margin="0px 0px 8px 0px"
          width="100%"
        >
          Створіть свій шлях до вирішення проблеми
        </Text>
        <Text
          as="h2"
          margin="0px"
          font="--headline2"
          md-font="--headline2"
          color="--dark"
          width="40%"
          padding="0px 16px 0px 0px"
          md-width="100%"
          md-margin="0px 0px 32px 0px"
          md-padding="0px 0px 0px 0px"
        >
          Розпочніть свою юридичну подорож з упевненістю
        </Text>
        <Text
          margin="0px"
          font="--base"
          color="--grey"
          width="40%"
          padding="0px 16px 0px 16px"
          md-width="50%"
          md-padding="0px 16px 0px 0px"
          sm-width="100%"
          sm-margin="0px 0px 16px 0px"
          sm-padding="0px 0px 0px 0px"
        >
          У Legal Pro Firm ми запрошуємо вас стати невід'ємною частиною нашої
          історії. Разом ми зможемо зорієнтуватися в складнощах правової
          системи, прокладаючи шлях до вирішення проблеми та душевного спокою. З
          нашою командою на вашому боці, ви не просто клієнт - ви партнер на
          шляху до правосуддя.
        </Text>
        <Button
          margin="0px"
          font="--base"
          color="--dark"
          width="20%"
          padding="0px 16px 0px 16px"
          md-width="50%"
          md-padding="0px 0px 0px 16px"
          sm-width="100%"
          sm-padding="0px 0px 0px 0px"
          sm-margin="0px 0px 32px 0px"
          type="link"
          href="/contacts"
          text-align="center"
          align-self="auto"
          display="flex"
          justify-content="center"
          text-decoration-line="underline"
          align-items="center"
          border-radius="8px"
          background="rgba(0, 0, 0, 0)"
        >
          Звʼязатися з нами
        </Button>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
